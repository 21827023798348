import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import cn from 'classnames';
import Navbar from '@app/components/auth/navbar';
import { useAppSelector } from '@app/store/hooks';
import { selectFingerPrint, setFingerPrint } from '@app/store/pdf/slice';
import { useDispatch } from 'react-redux';
import { visitorIdentity } from '@app/utils/fingerprintUtils';

interface ILayoutProps {
    showSearchbar?: boolean;
    className?: string;
}

export default function Layout({ children, className, showSearchbar }: React.PropsWithChildren<ILayoutProps>) {
    const fingerprint = useAppSelector(selectFingerPrint);
    const dispatch = useDispatch();
    useEffect(() => {
        const visitorId = async () => {
            await visitorIdentity().then((r) => {
                dispatch(setFingerPrint(r));
            });
        };
        if (!fingerprint) {
            visitorId();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className="z-20 !min-h-screen !min-w-full bg-white-200 dark:bg-dark">
            <Navbar />
            <Box className="float-right min-h-calc-72 w-full !bg-white-200  px-5 lg:px-[60px]" component="main" sx={{ display: 'flex', width: '100%' }}>
                <div className={cn(`h-full w-full `, className)}>{children}</div>
            </Box>
        </div>
    );
}
