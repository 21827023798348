import Layout from '@app/layouts/_layout';
import Image from 'next/image';
import HeroIcon from '@app/assets/image/hero.png';
import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import Error from '@app/components/ui/error';
import Button from '@app/components/ui/button';
import { Typography } from '@mui/material';
import { useLazyGetAuthUrlQuery } from '@app/store/login/api';
import { statusErrorMessageHandler } from '@app/utils/errorUtils';
import { useRouter } from 'next/router';
import FeatureCard from '@app/components/cards/feature-card';
import { useTranslation } from 'next-i18next';
import { features } from '@app/constants/Features';
import { useBreakpoint } from '@app/lib/hooks/use-breakpoint';

const Home = () => {
    const { t } = useTranslation();
    const breakpoints = useBreakpoint();
    const router = useRouter();

    const email = Cookies.get('restrictEmail');

    const handleOnClick = async () => {
        try {
            await getAuthUrl(router.locale ?? 'en');
        } catch (e: any) {
            console.error('error: ', e);
        }
    };
    const [getAuthUrl, data] = useLazyGetAuthUrlQuery();
    if (data.isError) {
        // @ts-ignore
        statusErrorMessageHandler(data?.error.originalStatus || data?.error.status, t);
    }

    useEffect(() => {
        if (data.isSuccess) {
            router.push(data.data.authorizationUrl);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.isSuccess]);

    if (['xs', '2xs', 'sm'].indexOf(breakpoints) !== -1) {
        return <div className={'mx-auto flex h-screen w-screen items-center justify-center font-bold'}>{t('mobileNotSupported')}</div>;
    }

    if (email) return <Error email={email} />;
    return (
        <Layout className="relative flex h-full w-full flex-col items-center justify-center !bg-white-200 !p-0">
            <div data-testid="landing-page" className="mb-10 mt-[120px] flex h-full w-full max-w-[1440px] flex-col items-center justify-center  gap-10 text-center sm:mb-20 sm:mt-[180px]  sm:gap-20">
                <div className="flex w-full flex-col items-center justify-center gap-5 sm:gap-10 ">
                    <Typography dangerouslySetInnerHTML={{ __html: t('landingPage.heroSection.title') }}></Typography>
                    <p className="heroSubtitle max-w-[823px] !font-normal text-black-700">{t('landingPage.heroSection.subtitle')}</p>
                    <Button data-testid="get-started" onClick={handleOnClick} size="large" className="!h-[40px] !rounded-[4px] !px-8 !py-6 !font-semibold">
                        {t('getStarted')}
                    </Button>
                    <div className="mt-5 sm:mt-10">
                        <Image alt="hero image" src={HeroIcon} />
                    </div>
                </div>
                <div className="flex flex-col items-center">
                    <p className="h1">{t('features')}</p>
                    <div className="mt-5  flex flex-wrap items-center justify-center gap-5 sm:mt-10 sm:gap-10">
                        {features.map((feature) => (
                            <FeatureCard key={feature.title} feature={feature} />
                        ))}
                    </div>
                </div>
            </div>
            <div className="relative flex h-[60px] w-full items-center justify-center">
                <p>&#169; 2024 PDF Editor</p>
            </div>

            {/*<div*/}
            {/*    className="fixed -bottom-7 left-0 !z-10 h-[200px] w-[330px] lg:aspect-cloud-image lg:h-[302px] lg:w-[503px]">*/}
            {/*    <Image alt="backgroundIcon" layout="fill" src={BackgroundIcon}/>*/}
            {/*</div>*/}
            {/*<div*/}
            {/*    className="fixed -bottom-7 right-0 !z-10 aspect-cloud-image-small h-[200px] w-[330px] object-contain md:aspect-cloud-image lg:aspect-cloud-image lg:h-[302px] lg:w-[503px]  ">*/}
            {/*    <Image alt="backgroundIcon" src={BackgroundIcon} className="flip" layout="fill"/>*/}
            {/*</div>*/}
        </Layout>
    );
};
export { getLandingPageServerSideProps as getServerSideProps } from '@app/lib/serverSideProps';
export default Home;
